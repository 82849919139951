import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import {
  Intro,
  Section,
  FeaturedCard,
  Carousel,
  CardList,
  Card,
  Prefooter,
  Swirl,
  Locator
} from '../partials/components';
import { FaChevronRight } from 'react-icons/fa';
import Img from 'gatsby-image';
import { PageTransition } from '../helpers/pageTransitions';

class ShowroomsPage extends Component {
  render() {
    const {
      showrooms: {
        seoMetaTags,
        bannerImage,
        title,
        leadText,
        leadHeading,
        featureText,
        featureHeading
      },
      globalConfig: { defaultPathways, contactPage }
    } = this.props.data;

    const allPathways = [...defaultPathways];

    return (
      <PageTransition>
        <HelmetDatoCms seo={seoMetaTags} />
        <Intro
          title={title}
          breadcrumbs={this.props.pageContext.breadcrumbs}
          action={{
            iconAfter: <FaChevronRight />,
            gaTarget: 'spa-chooser-cta',
            children: 'CHOOSE YOUR JACUZZI®',
            to: '/spa-chooser'
          }}
        ></Intro>
        <Locator
          image={bannerImage}
          leadText={leadText}
          leadHeading={leadHeading}
          featureText={featureText}
          featureHeading={featureHeading}
        />
        <Section animate={true} background={'light-gray'} padding={'top'}>
          <Prefooter contactPagePath={contactPage.path} />
        </Section>
      </PageTransition>
    );
  }
}

export default ShowroomsPage;

export const pageQuery = graphql`
  query ShowroomQuery($language: String) {
    showrooms: datoCmsShowroom(locale: { eq: $language }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      leadText
      leadHeading
      featureText
      featureHeading
      title
      bannerImage {
        fluid(
          maxWidth: 1600
          imgixParams: { w: "1920", h: "1000", fit: "crop", q: 70 }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    globalMeta: datoCmsSite(locale: { eq: $language }) {
      name
      locales
      globalSeo {
        titleSuffix
        twitterAccount
        siteName
        facebookPageUrl
        fallbackSeo {
          description
          image {
            url
          }
          title
          twitterCard
        }
      }
      faviconMetaTags {
        tags
      }
    }
    globalConfig: datoCmsConfig(locale: { eq: $language }) {
      contactPage {
        path
      }
      defaultPathways {
        ...Card
      }
    }
  }
`;
